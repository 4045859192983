import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { logout } from "../utils/common";

export const COURIERS_URL = process.env.REACT_APP_COURIERS_URL + "/api";

const couriersApi = axios.create({
    withCredentials: true,
    baseURL: COURIERS_URL,
});

couriersApi.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("couriersAuthTokens")!).access}`;
    return config;
});

couriersApi.interceptors.response.use(
    (config) => {
        return config;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error?.response?.status === 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            try {
                const initial_token = JSON.parse(localStorage.getItem("couriersAuthTokens")!);
                const response = await axios.post(`${COURIERS_URL}/token/refresh/`, { refresh: initial_token.refresh });
                initial_token.access = response.data.access;
                localStorage.setItem("couriersAuthTokens", JSON.stringify(initial_token));
                return couriersApi.request(originalRequest);
            } catch (e) {
                let realError = true;
                await axios
                    .get(COURIERS_URL + "/global-token-verify/", {
                        params: { global_token: JSON.parse(localStorage.getItem("authTokens")!).refresh },
                    })
                    .then((response) => {
                        localStorage.setItem("couriersAuthTokens", JSON.stringify(response.data));
                        realError = false;
                    })
                    .catch((e) => {
                        if (e.toJSON().status === 401) {
                            logout();
                        } else {
                            console.error(e);
                            enqueueSnackbar("Ошибка авторизации в сервисе курьеров");
                            throw error;
                        }
                    });
                if (!realError) return couriersApi.request(originalRequest);
            }
        }
        throw error;
    }
);

export default couriersApi;
