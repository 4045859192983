import MainLayout from "../../../../layouts/MainLayout";
import BackNav from "../BackNav";
import Store from "./store";
import { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { DataGrid, GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { Dayjs } from "dayjs";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import { CustomToolbar, CustomPagination } from "./components/common";
import { useCallback } from "react";
import "./styles.css";
import { styled } from "@mui/material/styles";

const headerClass = "super-app-theme--header";

const StyledDataGrid = styled(DataGrid)(({ theme }) => [
    {
        "&.MuiDataGrid-root": {
            border: "none",
        },
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#ffffff",
        },
    },
    theme.applyStyles("dark", {
        "& .MuiDataGrid-columnHeader": {
            backgroundColor: "#282828",
        },
        // "& .MuiDataGrid-scrollbarFiller": {
        //     backgroundColor: "#282828",
        //     // display: "none",
        // },
    }),
]);

const MyTickets = () => {
    const [store] = useState(() => new Store());

    let columns: GridColDef[] = [
        {
            field: "type",
            headerName: "Тип заявки",
            renderCell: (params) => {
                if (params.row.external_status.system.code_name === "couriers") {
                    return (
                        <div>
                            <DirectionsRunIcon className="text-yellow-400" /> Курьеры
                        </div>
                    );
                }
                return null;
            },
            width: 150,
        },
        {
            field: "description",
            headerName: "Описание",
            flex: 1,
            minWidth: 150,
        },
        {
            field: "status",
            headerName: "Статус",
            valueGetter: (value, row) => {
                return row.external_status.internal_name;
            },
            width: 150,
        },
        {
            field: "created_at",
            headerName: "Создана",
            valueFormatter: (value: Dayjs) => value.format("LLL"),
            flex: 1,
            minWidth: 150,
        },
    ];
    columns = columns.map((el) => ({ ...el, headerClassName: headerClass }));

    useEffect(() => {
        store.fetchTickets();
        store.fetchTicketTypes();
    }, []);

    const customToolbarConstructor = useCallback(() => {
        return CustomToolbar(store);
    }, []);

    const handleSearchChange = (newModel: GridFilterModel) => {
        if (newModel.quickFilterValues) {
            store.setSearch(newModel.quickFilterValues.join(" "));
        }
    };

    return (
        <MainLayout>
            <div className="avx-page-h mb-4">Мои заявки</div>
            <div className="mb-5 w-fit">
                <BackNav />
            </div>
            <div className="bg-white p-1 sm:p-3 md:p-5 lg:p-8 2xl:p-14 rounded-md 2xl:rounded-3xl dark:dark-surface-300 flex flex-col">
                <StyledDataGrid
                    slots={{ toolbar: customToolbarConstructor, pagination: CustomPagination }}
                    loading={store.ticketsLoading}
                    columns={columns}
                    rows={store.tickets}
                    disableColumnFilter
                    pageSizeOptions={[10, 30, 50]}
                    rowCount={store.totalRows}
                    paginationMode="server"
                    sortingMode="server"
                    filterMode="server"
                    paginationModel={{
                        pageSize: store.pageSize,
                        page: store.page - 1,
                    }}
                    initialState={{
                        sorting: {
                            sortModel: store.sortModel,
                        },
                    }}
                    onSortModelChange={store.handleOrderingChange}
                    onPaginationModelChange={store.handlePaginationModelChange}
                    disableRowSelectionOnClick
                    onFilterModelChange={handleSearchChange}
                    disableAutosize
                    disableColumnResize
                    autoHeight
                />
            </div>
        </MainLayout>
    );
};

export default observer(MyTickets);
