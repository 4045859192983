import MainLayout from "../../../../layouts/MainLayout";
import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import AuthContext, { AuthContextType } from "../../../../context/AuthContext";
import { TextField, Autocomplete, FormControl, createFilterOptions, Typography, Box } from "@mui/material";
import { observer, Observer } from "mobx-react-lite";
import CreationStore from "./store";
import "./styles.css";
import { enqueueSnackbar } from "notistack";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import Button from "../../../../components/inputs/Button";
import BackNav from "../BackNav";
import { useNavigate } from "react-router-dom";

const filter = createFilterOptions();

const CreateTicketCouriers = () => {
    const navigate = useNavigate();
    const { couriersStore } = useContext(AuthContext) as AuthContextType;
    const [creationStore] = useState(() => new CreationStore(couriersStore));

    const submitForm = async (e: BaseSyntheticEvent) => {
        e.preventDefault();
        await creationStore
            .submitForm()
            .then(() => navigate("../mine"))
            .catch((e) => console.error(e));
    };

    useEffect(() => {
        const couriersLogin = async () => {
            await couriersStore.globalLogin();
        };
        if (!couriersStore.isAuth) {
            couriersLogin();
        }
    }, []);

    useEffect(() => {
        if (couriersStore.isAuth) {
            creationStore.fetchCompanies();
        }
    }, [couriersStore.isAuth]);

    return (
        <MainLayout>
            <div className="avx-page-h mb-4">Создание заявки</div>
            <div className="mb-5 w-fit">
                <BackNav />
            </div>
            <div className="grid grid-cols-1 xl:grid-cols-2">
                <form
                    onSubmit={submitForm}
                    className="grid grid-cols-1 gap-5 2xl:gap-10 bg-white dark:dark-surface-300 p-3 2xl:p-10 rounded-lg 2xl:rounded-3xl"
                >
                    <div>
                        <TextField
                            autoFocus
                            fullWidth
                            required
                            label="Задание"
                            value={creationStore.task}
                            onChange={(event) => creationStore.setTask(event.target.value)}
                        />
                    </div>
                    <div className="form-card">
                        <div className="text-lg">Компания</div>
                        <div className="my-card-inner">
                            <FormControl fullWidth variant="standard">
                                <Autocomplete
                                    value={creationStore.company as any}
                                    onChange={async (event, newValue: any) => {
                                        const result = await creationStore.companyChange(event, newValue);
                                        if (!result.success) {
                                            enqueueSnackbar(<Typography>{result.message}</Typography>);
                                        }
                                    }}
                                    options={creationStore.companies}
                                    renderInput={(params) => (
                                        <TextField {...params} required label="Название" variant="standard" />
                                    )}
                                    freeSolo
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    )}
                                    filterOptions={(options, params): any => {
                                        const filtered = filter(options, params as any);

                                        const { inputValue } = params;

                                        const isExisting = options.some((option) => inputValue === option.name);
                                        if (inputValue !== "" && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                name: `Добавить "${inputValue}"`,
                                                id: "newValue",
                                            });
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(option: any) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.name;
                                    }}
                                />
                            </FormControl>
                            <TextField
                                id="company_address"
                                name="company_address"
                                variant="standard"
                                label="Адрес"
                                fullWidth
                                value={creationStore.companyAddress}
                                onChange={(event) => creationStore.companyAddressChange(event.target.value)}
                                required
                            />
                        </div>
                    </div>
                    <div className="form-card">
                        <div className="text-lg">Контактное лицо </div>
                        <div className="my-card-inner">
                            <FormControl fullWidth variant="standard">
                                <Autocomplete
                                    value={creationStore.contactPerson as any}
                                    onChange={creationStore.contactPersonChange}
                                    freeSolo
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    disabled={creationStore.contactPersonDisabled}
                                    renderInput={(params) => {
                                        return (
                                            <Observer>
                                                {() => (
                                                    <TextField
                                                        {...params}
                                                        label="Имя"
                                                        variant="standard"
                                                        error={creationStore.contactPersonError}
                                                        helperText={
                                                            creationStore.contactPersonError ? "Обязательное поле" : ""
                                                        }
                                                    />
                                                )}
                                            </Observer>
                                        );
                                    }}
                                    options={creationStore.contactPersons}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.fullname}
                                        </Box>
                                    )}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);

                                        const { inputValue } = params;

                                        const isExisting = options.some((option) => inputValue === option.fullname);
                                        if (inputValue !== "" && !isExisting) {
                                            filtered.push({
                                                inputValue,
                                                fullname: `Добавить "${inputValue}"`,
                                                id: "newValue",
                                            });
                                        }

                                        return filtered;
                                    }}
                                    getOptionLabel={(option) => {
                                        if (typeof option === "string") {
                                            return option;
                                        }
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        return option.fullname;
                                    }}
                                />
                            </FormControl>
                            <TextField
                                disabled={creationStore.contactPersonDisabled}
                                id="contact_person_phone_number"
                                name="contact_person_phone_number"
                                variant="standard"
                                label="Телефон"
                                fullWidth
                                value={creationStore.phoneNumber}
                                onChange={(event) => creationStore.phoneNumberChange(event.target.value)}
                                error={creationStore.phoneNumberError}
                                helperText={creationStore.phoneNumberError ? "Обязательное поле" : ""}
                            />
                        </div>
                    </div>
                    <TextField
                        id="comment"
                        name="comment"
                        label="Комментарий"
                        fullWidth
                        value={creationStore.comment}
                        onChange={(event) => creationStore.setComment(event.target.value)}
                        multiline
                        rows={3}
                    />
                    <FormControl fullWidth variant="standard">
                        <DatePicker
                            label="Срок исполнения"
                            value={creationStore.expiresAt}
                            onChange={(newValue) => creationStore.setNewExpirationDate(newValue as Dayjs)}
                            slotProps={{
                                textField: {
                                    error: creationStore.expiresAtError,
                                    helperText: creationStore.expiresAtError ? "Неверный формат даты" : "",
                                    required: true,
                                },
                            }}
                            format="LL"
                            views={["year", "month", "day"]}
                            disablePast
                        />
                    </FormControl>
                    <Button text="Отправить" name="publish" loading={creationStore.loadingSubmit} className={"w-fit"} />
                </form>
            </div>
        </MainLayout>
    );
};

export default observer(CreateTicketCouriers);
