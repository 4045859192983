import dayjs, { Dayjs } from "dayjs";
import api from "../../../../http";
import { makeAutoObservable } from "mobx";
import { enqueueSnackbar } from "notistack";
import { GridSortModel, GridPaginationModel } from "@mui/x-data-grid";
import { reaction } from "mobx";

export interface SystemInterface {
    id: number;
    code_name: string;
}

interface ExternalStatusInterface {
    id: number;
    internal_name: string;
    system: SystemInterface;
}

interface TicketInterface {
    id: number;
    ext_id: string;
    created_at: Dayjs;
    description: string;
    external_status: ExternalStatusInterface;
}

interface TicketsServerInterface {
    created_at: string;
}

export default class MyTicketsStore {
    types: SystemInterface[] = [];
    selectedType: SystemInterface | null = null;
    tickets: TicketInterface[] = [];
    ticketsLoading: boolean = true;

    sortModel: GridSortModel = [{ field: "created_at", sort: "desc" }];

    totalRows: number = 0;
    page: number = 1;
    pageSize: number = 10;
    ordering: string = "-created_at";
    search: string = "";

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.ordering,
            async () => await this.fetchTickets()
        );

        reaction(
            () => this.page,
            async () => await this.fetchTickets()
        );

        reaction(
            () => this.pageSize,
            async () => await this.fetchTickets()
        );

        reaction(
            () => this.selectedType,
            async () => await this.fetchTickets()
        );

        reaction(
            () => this.search,
            async () => await this.fetchTickets()
        );
    }

    setTickets = (arr: Array<TicketInterface>) => {
        this.tickets = arr;
    };

    setTicketsLoading = (bool: boolean) => {
        this.ticketsLoading = bool;
    };

    setTotalRows = (num: number) => {
        this.totalRows = num;
    };

    setPage = (num: number) => {
        this.page = num;
    };

    setPageSize = (num: number) => {
        this.pageSize = num;
    };

    setOrdering = (str: string) => {
        this.ordering = str;
    };

    setSearch = (str: string) => {
        this.search = str;
    };

    setTypes = (arr: SystemInterface[]) => {
        this.types = arr;
    };

    setSelectedType = (newType: SystemInterface | null) => {
        this.selectedType = newType;
    };

    fetchTickets = async () => {
        this.setTicketsLoading(true);
        await api
            .get("/tickets/my-tickets/", {
                params: {
                    page: this.page,
                    page_size: this.pageSize,
                    ordering: this.ordering,
                    external_status__system__code_name: this.selectedType ? this.selectedType.code_name : "",
                    search: this.search,
                },
            })
            .then((resp) => {
                this.setTickets(
                    resp.data.results.map((el: TicketsServerInterface) => {
                        return { ...el, created_at: dayjs(el.created_at) };
                    })
                );
                this.setTotalRows(resp.data.count);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса заявок");
            })
            .finally(() => {
                this.setTicketsLoading(false);
            });
    };

    handleOrderingChange = (newModel: GridSortModel) => {
        const ordering = newModel.map((el) => {
            return el.sort === "asc" ? el.field : "-" + el.field;
        });
        this.setOrdering(ordering.join(","));
    };

    handlePaginationModelChange = (newModel: GridPaginationModel) => {
        this.setPage(newModel.page + 1);
        this.setPageSize(newModel.pageSize);
    };

    fetchTicketTypes = async () => {
        await api
            .get("/tickets/types/")
            .then((resp) => this.setTypes(resp.data))
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса типов заявок");
            });
    };
}
