import { makeAutoObservable } from "mobx";
import couriersApi from "../../../../http/couriers";
import { enqueueSnackbar } from "notistack";
import dayjs, { Dayjs } from "dayjs";
import CouriersStore from "../../../../stores/couriersStore";
import api from "../../../../http";

interface TicketForm {
    id?: number;
    name?: string;
    address: string;
}

interface CompanyForm {
    id?: number;
    fullname?: string;
    phone_number: string;
}

interface InputContactPerson {
    inputValue: string;
    fullname: string;
}

interface FunctionResult {
    success: boolean;
    message: string;
}

interface InputCompany {
    inputValue: string;
    name: string;
}

interface ServerContactPerson {
    id: number;
    fullname: string;
    mail: string | null;
    phone_number: string | null;
    company: number;
    comment: string | null;
}

interface ServerCompany {
    id: number;
    name: string;
    address: string | null;
    work_time: string | null;
    comment: string | null;
    contactperson_set: Array<ServerContactPerson>;
}

interface MainFormInterface {
    task: string;
    company: TicketForm;
    contact_person: CompanyForm | null;
    expires_at: string;
    comment?: string;
}

export default class CreationStore {
    couriersStore: CouriersStore;
    task: string = "";

    company: ServerCompany | InputCompany | null = null;
    companyAddress: string = "";
    companyAddressError: boolean = false;

    contactPerson: ServerContactPerson | InputContactPerson | null = null;
    contactPersonError: boolean = false;
    contactPersonDisabled: boolean = true;
    phoneNumber: string = "";
    phoneNumberError: boolean = false;

    comment: string = "";
    expiresAt: Dayjs = dayjs();
    expiresAtError: boolean = false;

    companies: Array<ServerCompany> = [];
    contactPersons: Array<ServerContactPerson> = [];

    loadingSubmit: boolean = false;

    constructor(couriersStore: CouriersStore) {
        makeAutoObservable(this);
        this.couriersStore = couriersStore;
    }

    setTask = (task: string) => {
        this.task = task;
    };

    setCompanies = (arr: Array<ServerCompany>) => {
        this.companies = arr;
    };

    setContactPersons = (arr: Array<ServerContactPerson>) => {
        this.contactPersons = arr;
    };

    setContactPersonDisabled = (bool: boolean) => {
        this.contactPersonDisabled = bool;
    };

    fetchCompanies = async () => {
        await couriersApi
            .get("/company")
            .then((resp) => {
                this.setCompanies(resp.data);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса компаний");
            });
    };

    fetchContactPersons = async (companyId: number) => {
        await couriersApi
            .get("/contact-person", { params: { company: companyId } })
            .then((resp) => {
                this.setContactPersons(resp.data);
                this.setContactPersonDisabled(false);
            })
            .catch((e) => {
                console.error(e);
                enqueueSnackbar("Ошибка запроса контактных лиц");
            });
    };

    companyChange = async (
        event: React.SyntheticEvent,
        newValue: ServerCompany | InputCompany | null
    ): Promise<FunctionResult> => {
        let result = {
            success: true,
            message: "",
        };
        this.company = newValue;
        this.contactPerson = null;
        this.contactPersonError = false;
        this.companyAddressError = false;
        this.phoneNumberError = false;

        // Если это существующая компания
        if (newValue && !("inputValue" in newValue)) {
            this.companyAddress = newValue.address ? newValue.address : "";
            // запросим всех контактных лиц для компании
            await this.fetchContactPersons(newValue.id);
        }
        // если это новая компания
        else if (newValue && "inputValue" in newValue) {
            this.companyAddress = "";
            this.contactPersonDisabled = false;
            this.phoneNumber = "";
        }
        // если просто удаляем поле
        else {
            this.companyAddress = "";
            this.contactPersonDisabled = true;
            this.phoneNumber = "";
        }

        return result;
    };

    companyAddressChange = (value: string) => {
        this.companyAddress = value;
        this.companyAddressError = false;
    };

    contactPersonChange = (event: React.SyntheticEvent, newValue: ServerContactPerson | InputContactPerson | null) => {
        this.contactPerson = newValue;
        this.phoneNumberError = false;
        this.contactPersonError = false;

        // если это новое контактное лицо или поле в принципе очищается
        if ((newValue && "inputValue" in newValue) || !newValue) {
            this.phoneNumber = "";
            // если существующее контактное лицо
        } else {
            this.phoneNumber = newValue.phone_number ? newValue.phone_number : "";
        }
    };

    phoneNumberChange = (value: string) => {
        this.phoneNumber = value;
        this.phoneNumberError = false;
    };

    setComment = (comment: string) => {
        this.comment = comment;
    };

    setNewExpirationDate = (date: Dayjs) => {
        this.expiresAt = date;
        this.expiresAtError = false;
    };

    setLoadingSubmit = (bool: boolean) => {
        this.loadingSubmit = bool;
    };

    submitForm = async () => {
        // сначала проверим ошибки в форме
        let error_flag = false;
        if (!this.expiresAt.isValid()) {
            this.expiresAtError = true;
            error_flag = true;
        }
        if (this.contactPerson && !this.phoneNumber.trim()) {
            this.phoneNumberError = true;
            error_flag = true;
        }
        if (this.phoneNumber.trim() && !this.contactPerson) {
            this.contactPersonError = true;
            error_flag = true;
        }
        if (error_flag) {
            throw "Форма не валидна";
        }

        // попробуем создать заявку
        this.setLoadingSubmit(true);
        let company: TicketForm = { address: this.companyAddress };
        let contact_person: CompanyForm | null = null;

        if (this.company && "id" in this.company && String(this.company.id) !== "newValue") {
            company.id = this.company.id;
        } else if (this.company && "inputValue" in this.company) {
            company.name = this.company.inputValue;
        }

        if (this.contactPerson) {
            contact_person = { phone_number: this.phoneNumber };
            if (this.contactPerson && "id" in this.contactPerson && String(this.contactPerson.id) !== "newValue") {
                contact_person.id = this.contactPerson.id;
            } else if ("inputValue" in this.contactPerson) {
                contact_person.fullname = this.contactPerson.inputValue;
            }
        }

        const data: MainFormInterface = {
            task: this.task,
            company,
            contact_person,
            expires_at: this.expiresAt.format("YYYY-MM-DD"),
        };
        if (this.comment.trim()) data.comment = this.comment;

        return await this.couriersStore
            .globalLogin()
            .then(async () => {
                await api
                    .post("/tickets/create/couriers/", {
                        couriers_token: JSON.parse(localStorage.getItem("couriersAuthTokens")!).access,
                        data,
                    })
                    .then(() => {
                        enqueueSnackbar("Заявка успешно создана");
                    })
                    .catch((e) => {
                        console.error(e);
                        enqueueSnackbar("Ошибка создания заявки");
                        throw e;
                    });
            })
            .finally(() => {
                this.setLoadingSubmit(false);
            });
    };
}
