import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "./routes";
import "./App.css";
import "react-image-crop/dist/ReactCrop.css";
import { AuthProvider } from "./context/AuthContext";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import objectSupport from "dayjs/plugin/objectSupport";
import duration from "dayjs/plugin/duration";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { SnackbarProvider } from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./utils/theme";
import { ReactNode, useContext, useEffect } from "react";
import AuthContext from "./context/AuthContext";
import { observer } from "mobx-react-lite";
import CssBaseline from "@mui/material/CssBaseline";
import { useColorScheme } from "@mui/material/styles";
import { AuthContextType } from "./context/AuthContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { localeText } from "./utils/muiLocaleText";

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(objectSupport);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.locale("ru");

const router = createBrowserRouter(routes);

const ThemeWrapper = observer(({ children }: { children: ReactNode }) => {
    const { domainStore } = useContext(AuthContext) as AuthContextType;

    useEffect(() => {
        if (domainStore.theme === "dark") {
            document.documentElement.classList.add("dark");
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#282828");
        } else {
            document.documentElement.classList.remove("dark");
            document.querySelector('meta[name="theme-color"]')?.setAttribute("content", "#F4F4F6");
        }
    }, [domainStore.theme]);

    return <div>{children}</div>;
});

const MuiThemeWrapper = observer(({ children }: { children: ReactNode }) => {
    const { mode, setMode } = useColorScheme();

    const { domainStore } = useContext(AuthContext) as AuthContextType;

    useEffect(() => {
        if (domainStore.theme === "dark") {
            setMode("dark");
        } else {
            setMode("light");
        }
    }, [domainStore.theme]);
    return <div>{children}</div>;
});

function App() {
    return (
        <AuthProvider>
            <ThemeWrapper>
                <SnackbarProvider
                    maxSnack={5}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                />
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <MuiThemeWrapper>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="ru"
                            localeText={{ ...localeText }}
                        >
                            <RouterProvider router={router} />
                        </LocalizationProvider>
                    </MuiThemeWrapper>
                </ThemeProvider>
            </ThemeWrapper>
        </AuthProvider>
    );
}

export default App;
