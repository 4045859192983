import {
    GridToolbarContainer,
    GridToolbarQuickFilter,
    GridPagination,
    useGridApiContext,
    useGridSelector,
    gridPageCountSelector,
} from "@mui/x-data-grid";
import { Box, Autocomplete, TextField, TablePaginationProps } from "@mui/material";
import MyTicketsStore from "../store";
import { BaseSyntheticEvent } from "react";
import { SystemInterface } from "../store";
import Pagination from "@mui/material/Pagination";

export const CustomToolbar = (store: MyTicketsStore) => {
    const label = "Тип заявки";

    const handleTypeChange = (e: BaseSyntheticEvent, newVal: SystemInterface | null) => {
        store.setSelectedType(newVal);
    };

    return (
        <GridToolbarContainer sx={{ mb: 1, pt: 1, px: 1 }}>
            <div className="w-40">
                <Autocomplete
                    fullWidth
                    size="small"
                    options={store.types}
                    renderInput={(params) => <TextField {...params} label={label} />}
                    renderOption={(props, option) => {
                        let name: string = "";
                        if (option.code_name === "couriers") name = "Курьеры";
                        return (
                            <li {...props} key={option.id}>
                                {name}
                            </li>
                        );
                    }}
                    getOptionLabel={(option) => {
                        let name: string = "";
                        if (option.code_name === "couriers") name = "Курьеры";
                        return name;
                    }}
                    value={store.selectedType}
                    onChange={handleTypeChange}
                />
            </div>
            <Box sx={{ flexGrow: 1 }} />
            <GridToolbarQuickFilter />
        </GridToolbarContainer>
    );
};

const MyPagination = ({
    page,
    onPageChange,
    className,
}: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) => {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            className={className}
            count={pageCount}
            page={page + 1}
            onChange={(event, newPage) => {
                onPageChange(event as any, newPage - 1);
            }}
            color="primary"
            showFirstButton
            showLastButton
        />
    );
};

export const CustomPagination = (props: any) => {
    return <GridPagination ActionsComponent={MyPagination} {...props} />;
};
