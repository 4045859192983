import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Link } from "react-router-dom";

const BackNav = () => {
    return (
        <Link to=".." className="flex items-center gap-2">
            <ArrowBackIosNewIcon className="!size-5" />
            <span>Заявки</span>
        </Link>
    );
};

export default BackNav;
