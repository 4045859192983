import { makeAutoObservable } from "mobx";
import { COURIERS_URL } from "../http/couriers";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { jwtDecode } from "jwt-decode";
import { logout } from "../utils/common";

export default class CouriersStore {
    tokenData = localStorage.getItem("couriersAuthTokens")
        ? jwtDecode(JSON.parse(localStorage.getItem("couriersAuthTokens")!).access)
        : {};
    isAuth = !!localStorage.getItem("couriersAuthTokens");

    constructor() {
        makeAutoObservable(this);
    }

    setAuth = (bool: boolean) => {
        this.isAuth = bool;
    };

    setTokenData = (data: object) => {
        this.tokenData = data;
    };

    globalLogin = async () => {        
        await axios
            .get(COURIERS_URL + "/global-token-verify/", {
                params: { global_token: JSON.parse(localStorage.getItem("authTokens")!).refresh },
            })
            .then((response) => {
                if (!response.data.access) {
                    throw ''
                }
                localStorage.setItem("couriersAuthTokens", JSON.stringify(response.data));
                this.setAuth(true);
                this.setTokenData(jwtDecode(response.data.access));
            })
            .catch((e) => {
                if (typeof e !== "string" && e.toJSON().status === 401) {
                    logout();
                } else {
                    console.error(e);
                    enqueueSnackbar("Ошибка авторизации в сервисе курьеров");
                }
            });
    };
}
