import React from "react";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login";
import CreateNews from "../pages/News/CreateNews";
import PrivateRoute from "../components/PrivateRoute";
import MyNews from "../pages/News/MyNews";
import EditNews from "../pages/News/EditNews";
import AllNews from "../pages/News/AllNews";
import ViewNews from "../pages/News/ViewNews";
import MyProfile from "../pages/Users/MyProfile";
import AllUsers from "../pages/Users/AllUsers";
import UserProfile from "../pages/Users/UserProfile";
import CompanyStructure from "../pages/Users/CompanyStructure";
import NotFound from "../pages/errors/404";
import Vacation from "../pages/Vacation";
import Calendar from "../pages/Calendar";
import Admin from "../pages/Admin";
import PermissionRoute from "../components/PermissionRoute";
import AdminVacation from "../pages/Admin/components/Vacation";
import Tickets from "../pages/Tickets";
import CreateTicketCouriers from "../pages/Tickets/components/CreateTicketCouriers";
import MyTickets from "../pages/Tickets/components/MyTickets";

interface RouteSubItem {
    path?: string;
    element: React.ReactNode;
    index?: boolean;
    children?: any;
}

interface RouteItem {
    path: string;
    element: React.ReactNode;
    children?: Array<RouteSubItem>;
}

const routes: Array<RouteItem> = [
    { path: "/login", element: <LoginPage /> },
    {
        path: "/",
        element: <PrivateRoute />,
        children: [{ index: true, element: <HomePage /> }],
    },

    // Новости
    {
        path: "/news",
        element: <PrivateRoute />,
        children: [
            { index: true, element: <NotFound /> },
            { path: "create", element: <CreateNews /> },
            { path: "mine", element: <MyNews /> },
            { path: "all", element: <AllNews /> },
            { path: "edit/:id", element: <EditNews /> },
            { path: "view/:id", element: <ViewNews /> },
        ],
    },

    // Пользователи
    {
        path: "/users",
        element: <PrivateRoute />,
        children: [
            { index: true, element: <NotFound /> },
            { path: "me", element: <MyProfile /> },
            { path: "all", element: <AllUsers /> },
            { path: "profile/:id", element: <UserProfile /> },
        ],
    },

    // Компания
    {
        path: "/company",
        element: <PrivateRoute />,
        children: [
            { index: true, element: <NotFound /> },
            { path: "structure", element: <CompanyStructure /> },
            // { path: "about", element: <About /> },
        ],
    },

    // Отпуск
    {
        path: "/vacation",
        element: <PrivateRoute />,
        children: [{ index: true, element: <Vacation /> }],
    },

    // Админ-панель
    {
        path: "/admin",
        element: <PrivateRoute />,
        children: [
            {
                path: "/admin",
                element: <PermissionRoute permissionList={["portalAdmin"]} />,
                children: [
                    { index: true, element: <Admin /> },
                    { path: "vacation", element: <AdminVacation /> },
                ],
            },
        ],
    },

    // Заявки
    {
        path: "/tickets",
        element: <PrivateRoute />,
        children: [
            { index: true, element: <Tickets /> },
            { path: "create/couriers", element: <CreateTicketCouriers /> },
            { path: "mine", element: <MyTickets /> },
        ],
    },

    // Другое
    { path: "/calendar", element: <PrivateRoute />, children: [{ index: true, element: <Calendar /> }] },

    // Ошибки
    { path: "*", element: <NotFound /> },
];

export default routes;
