import AuthContext, { AuthContextType } from "../../context/AuthContext";
import MainLayout from "../../layouts/MainLayout";
import { useContext, useEffect, BaseSyntheticEvent } from "react";
import { observer } from "mobx-react-lite";
import { Loading } from "../../components/ListFeedback";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';

const Tickets = observer(() => {
    const navigate = useNavigate();

    const redirect = (e: BaseSyntheticEvent) => {
        e.preventDefault();
        navigate("./create/" + e.target.id);
    };

    return (
        <div id="service-choice-block" className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-5">
            <a onClick={redirect} id="couriers" href="/tickets/create/couriers">
                Курьер
            </a>
            <a onClick={redirect}>Оформление пропуска</a>
            <a onClick={redirect}>Служба технической поддержки</a>
        </div>
    );
});

const TicketsWrapper = () => {
    const { couriersStore } = useContext(AuthContext) as AuthContextType;

    useEffect(() => {
        const couriersLogin = async () => {
            await couriersStore.globalLogin();
        };
        if (!couriersStore.isAuth) {
            couriersLogin();
        }
    }, []);

    return (
        <MainLayout>
            <div className="flex items-center justify-between w-full gap-3 mb-4">
                <div className="avx-page-h">Заявки</div>
                <Link className="bg-white dark:dark-surface-400 p-1 rounded-xl text-sm" to="./mine" title="Мои заявки">
                    <MenuIcon/>
                </Link>
            </div>
            {!couriersStore.isAuth ? (
                <div className="flex justify-center">
                    <Loading />
                </div>
            ) : (
                <Tickets />
            )}
        </MainLayout>
    );
};

export default observer(TicketsWrapper);
